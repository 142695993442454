import React, { useState, useEffect } from 'react';
import moment from 'moment';
import jsYaml from 'js-yaml';
import axios from 'axios';
import useSharedTabState from '../../hooks/tabState';
// import oepBannerIcom from '../../images/icons/oep-banner-close.svg';
import envConstants from '../../globals/constants/environment-constants';
import useSharedOepData from '../../hooks/oepData';
import CountDown from './CountDown';
import { getBrowserStorage } from '../../common';

const OepBanner = () => {
  const { active } = useSharedTabState();
  const { stateAbbr, setStateAbbr, countDownData, setCountDownData } = useSharedOepData();
  const [dateLeft, setDateLeft] = useState(0);
  // const [OEPConfig, setOEPConfig] = useState({});
  const [oepPeriods, setOepPeriods] = useState({});
  const [activeOepBannerConfig, setActiveOepBannerConfig] = useState(null);
  const [isDuringOep, setIsDuringOep] = useState(false);
  const [isShow, setIsShow] = useState(true);
  const [isShowCountDown, setIsShowCountDown] = useState(false);

  const getCountdownDate = (to) => {
    const startDate = moment();
    const endDate = moment(to);
    return startDate.isBefore(endDate) ? endDate.diff(startDate, 'days') : 0;
  };

  const setOepOptions = (oepPeriodsData: any) => {
    const oepPeriod = oepPeriodsData[stateAbbr] || oepPeriodsData.DEFAULT;
    setIsDuringOep(
      !!oepPeriod &&
        moment().isSameOrAfter(moment(oepPeriod.from)) &&
        moment().isSameOrBefore(moment(oepPeriod.to)),
    );
    // Set date left
    setDateLeft(getCountdownDate(oepPeriod.to));
  };

  const setCountDownExisting = (oepPeriod, countDownDate) => {
    if (countDownDate) {
      setIsShowCountDown(
        !!oepPeriod &&
          moment().isSameOrAfter(moment(countDownDate.from)) &&
          moment().isSameOrBefore(moment(countDownDate.to)),
      );
    } else {
      setIsShowCountDown(false);
    }
  };

  const loadOEPConfig = async () => {
    try {
      const isLocal = window.location.hostname === 'localhost';
      const oepConfigUrl = `${
        isLocal ? 'https://www.qa.ehealthinsurance.com' : envConstants.SITE_URL
      }/ifp-api/misc/instant-config/yaml/oep`;
      axios
        .get(oepConfigUrl)
        .then((response) => {
          const oepConfig = jsYaml.load(response.data);
          // setOEPConfig(oepConfig);
          setOepPeriods(oepConfig.periods || {});
          setOepOptions(oepConfig.periods);

          const stateOepBannerConfig = oepConfig.banner[stateAbbr] || oepConfig.banner.DEFAULT;
          if (stateOepBannerConfig) {
            const oepBannerSettings =
              stateOepBannerConfig.find((item) =>
                moment().isBetween(moment(item.when[0]), moment(item.when[1])),
              ) || null;
            setActiveOepBannerConfig(oepBannerSettings);
            // Set count down dat
            if (oepBannerSettings) {
              setCountDownData(oepBannerSettings.countdown);
              setCountDownExisting(oepConfig.periods, oepBannerSettings.countdown);
            }
          } else {
            setActiveOepBannerConfig(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log('Fetch OEP configration fail.');
    }
  };

  const getGeoLocation = () => {
    try {
      const isLocal = window.location.hostname === 'localhost';
      const urlSearchParams = new URLSearchParams(window.location.search);
      const ipFromURL = urlSearchParams.get('_ipAddress');
      const ipParameter = ipFromURL ? `ipAddress=${ipFromURL}` : '';
      axios
        .get(
          `${
            isLocal ? 'https://www.qa.ehealthinsurance.com' : envConstants.SITE_URL
          }/ifp-api/misc/geo-location?${ipParameter}`,
        )
        .then((res) => {
          const stateAbbrFromApi = res.data.state;
          if (stateAbbrFromApi) {
            setStateAbbr(stateAbbrFromApi);
          } else {
            // if stateAbbr no existing in api
            setStateAbbr('DEFAULT');
          }
        });
    } catch (e) {
      console.log('Fetch geo location fail.');
    }
  };

  const handleCloseOepBanner = () => {
    setIsShow(false);
  };

  useEffect(() => {
    /* Check stateAbbr and load oep config if stateAbbr existing,
    otherwise get geo location and trigger the stateAbbr change if there is existing stateAbbr under IP */
    const zipCode = getBrowserStorage('zipValue');
    if (stateAbbr) {
      loadOEPConfig();
    } else if (!zipCode) {
      getGeoLocation();
    }
  }, [stateAbbr]);
  return (
    <div id="oep-banner-container" className={active === 1 && isDuringOep ? 'show' : ''}>
      <div className={`w-full bg-green-800 ${isShow ? '' : 'hidden'}`}>
        <div className="max-w-screen-xl mx-auto oep-banner-inner py-1 md:py-0">
          <div className="text-white text-base flex flex-col lg:flex-row items-center justify-center text-center p-3">
            <p className="lg:mr-2 text-14 md:text-16 py-0 lg:py-1 lg:text-left">
              <span
                dangerouslySetInnerHTML={{
                  __html: activeOepBannerConfig
                    ? (activeOepBannerConfig.message || '').replace(
                        'COUNT_DOWN',
                        `<b>${dateLeft}</b> day${dateLeft > 1 ? 's' : ''}`,
                      )
                    : '',
                }}
              />
            </p>
            {isShowCountDown ? <CountDown /> : ''}
          </div>
          {/* <div
            className="close-oep-btn cursor-pointer"
            role="button"
            tabIndex={0}
            onClick={handleCloseOepBanner}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleCloseOepBanner();
              }
            }}
          >
            <img src={oepBannerIcom} alt="Close OEP banner" />
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default OepBanner;
