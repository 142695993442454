import React from 'react';
import { useBetween } from 'use-between';
import moment from 'moment';
import { getBrowserStorage } from '../common';

const tabState = () => {
  const [active, setActive] = React.useState(0);

  return { active, setActive };
};

const useSharedTabState = () => useBetween(tabState);
const duringOEPStartDate = '2023/12/08 00:00:00 PST';
const duringOEPEndDate = '2024/01/25 23:59:59 PST';
const isDuringOEP = moment().isBetween(moment(duringOEPStartDate), moment(duringOEPEndDate));

export const setDefaultTab = (
  data: { allAllianceProductCsv: { nodes: [{ AllianceID: string; Account: string }] } },
  setActive: (args: number) => void,
) => {
  const productType = data.allAllianceProductCsv.nodes.find(
    ({ AllianceID }) => AllianceID === getBrowserStorage('allid'),
  );
  switch (productType?.Account?.toLowerCase()) {
    case 'ifp':
      setActive(1);
      break;
    case 'smb':
      setActive(2);
      break;
    default:
      if (isDuringOEP) {
        setActive(1);
      } else {
        setActive(0);
      }
  }
};

export default useSharedTabState;
