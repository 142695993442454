import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import useSharedTabState, { setDefaultTab } from '../hooks/tabState';
import PureIndex from '../components/pure-index/PureIndex';
import Meta from '../meta/Meta';

const Index = ({ data }) => {
  const { setActive } = useSharedTabState();

  React.useEffect(() => {
    setDefaultTab(data, setActive);
  }, []);
  return (
    <>
      <Meta />
      <PureIndex />
    </>
  );
};

Index.propTypes = {
  data: PropTypes.shape({
    allAllianceProductCsv: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({ Account: PropTypes.string, AllianceID: PropTypes.string }),
      ),
    }),
  }).isRequired,
};

export default Index;

export const query = graphql`
  query allianceMapping {
    allAllianceProductCsv {
      nodes {
        Account
        AllianceID
      }
    }
  }
`;
