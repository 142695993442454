import React, { useState, useEffect } from 'react';
import moment from 'moment';
import useSharedTabState from '../../hooks/tabState';
import envConstants from '../../globals/constants/environment-constants';
import { getBrowserStorage } from '../../common';

const BannerAd = () => {
  const { active } = useSharedTabState();
  const startingAEPStartDate = '2023/10/01 00:00:00 PDT';
  const startingAEPEndDate = '2023/10/14 23:59:59 PDT';
  const duringAEPStartDate = '2023/10/15 00:00:00 PDT';
  const duringAEPEndDate = '2023/11/24 23:59:59 PST';
  const endingAEPStartDate = '2023/11/25 00:00:00 PST';
  const endingAEPEndDate = '2023/12/7 23:59:59 PST';
  const [countDownStart, setCountDownStart] = useState(0);
  const [countDownEnd, setCountDownEnd] = useState(0);
  const [isShowBanner, setIsShowBanner] = useState(false);
  const [isStartingAEP, setIsStartingAEP] = useState(false);
  const [isDuringAEP, setIsDuringAEP] = useState(false);
  const [isEndingAEP, setIsEndingAEP] = useState(false);
  const addUrlParam = (url: string, key: string, value: string): string => {
    if (url !== null && url !== undefined) {
      const reg = new RegExp('([?&])' + key + '=.*?(&|$)', 'i'); // eslint-disable-line prefer-template
      const separator = url.indexOf('?') !== -1 ? '&' : '?';
      if (undefined !== value && value !== null) {
        if (url.match(reg)) {
          return url.replace(reg, `$1${key}=${value}$2`);
        }
        return `${url}${separator}${key}=${value}`;
      }
      if (url.match(reg)) {
        return url.replace(reg, '');
      }
    }
    return url;
  };
  const handleRedirectToEHMP = () => {
    const zipValue = getBrowserStorage('zipValue');
    const countyValue = getBrowserStorage('countyValue');
    console.log('zipValue:', zipValue, 'countyValue', countyValue);
    const urlParams = {
      zip: zipValue,
      county: countyValue,
      action: zipValue && countyValue ? 'redirect_with_zip_code' : '',
      lastV1Action: zipValue && countyValue ? 'quoteFromV1' : '',
      allid: '',
      gclid: '',
      mkt_code: '',
      msclkid: '',
      sid: '',
      pid: '',
      url: '',
      leadId: '',
      campaign: '',
      planYear: 2024,
    };
    let redirectUrl = `${envConstants.EHMP_URL}`;
    Object.keys(urlParams).forEach((key) => {
      const value = urlParams[key] || getBrowserStorage(key);
      if (value) {
        redirectUrl = addUrlParam(redirectUrl, key, value);
      }
    });
    // eslint-disable-next-line
    if (window._satellite) {
      // eslint-disable-next-line
      const paramList = window._satellite.getVar('Cross Domain Parameters');
      if (paramList) {
        paramList.forEach((param) => {
          redirectUrl = addUrlParam(redirectUrl, param.key, param.value);
        });
      }
    }
    window.location.assign(redirectUrl);
  };
  useEffect(() => {
    const startDate = moment();
    const oepStartDate = moment(duringAEPStartDate);
    const endDate = moment(endingAEPEndDate);
    const datesStartLeft = startDate.isBefore(oepStartDate)
      ? oepStartDate.diff(startDate, 'days')
      : 0;
    const datesEndLeft = startDate.isBefore(endDate) ? endDate.diff(startDate, 'days') : 1;
    setCountDownStart(datesStartLeft + 1);
    setCountDownEnd(datesEndLeft + 1);
    const isStartingAEPInner = moment().isBetween(
      moment(startingAEPStartDate),
      moment(startingAEPEndDate),
    );
    const isDuringAEPInner = moment().isBetween(
      moment(duringAEPStartDate),
      moment(duringAEPEndDate),
    );
    const isEndingAEPInner = moment().isBetween(
      moment(endingAEPStartDate),
      moment(endingAEPEndDate),
    );
    setIsStartingAEP(isStartingAEPInner);
    setIsDuringAEP(isDuringAEPInner);
    setIsEndingAEP(isEndingAEPInner);
    setIsShowBanner(isStartingAEPInner || isDuringAEPInner || isEndingAEPInner || false);
  }, []);
  return (
    <div id="banner-ad-container" className={active === 0 ? 'show' : 'hidden'}>
      {isShowBanner ? (
        <div
          className="w-full cursor-pointer"
          onClick={handleRedirectToEHMP}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleRedirectToEHMP();
            }
          }}
          id="aep-top-banner"
          data-wa-link="shop 2024 plans"
          role="button"
          tabIndex={0}
        >
          <div className="text-white flex flex-row items-center justify-center text-center p-3 text-14 leading-20 font-medium bg-green-800">
            <p className="lg:mr-2 text-14 py-1 md:py-0">
              {isDuringAEP ? (
                <span>
                  The Medicare Annual Enrollment Period is <b>Oct 15 - Dec 7</b>
                </span>
              ) : (
                ''
              )}
              {isStartingAEP ? (
                <span>
                  <b>{countDownStart}</b> day{countDownStart > 1 && <span>s</span>} until Medicare
                  Annual Enrollment Period
                </span>
              ) : (
                ''
              )}
              {isEndingAEP ? (
                <span>
                  <b>{countDownEnd}</b> day{countDownEnd > 1 && <span>s</span>} left for Medicare
                  Annual Enrollment
                </span>
              ) : (
                ''
              )}
            </p>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export default BannerAd;
