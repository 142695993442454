import React, { useState, useEffect } from 'react';
import moment from 'moment';
import jsYaml from 'js-yaml';
import axios from 'axios';
import useSharedTabState from '../../hooks/tabState';
import calendar from '../../images/icons/calendar.svg';
import envConstants from '../../globals/constants/environment-constants';
import { getBrowserStorage } from '../../common';
import useSharedOepData from '../../hooks/oepData';

const CountDown = () => {
  const { active } = useSharedTabState();
  const { countDownData, setCountDownData } = useSharedOepData();
  const [daysDiff, setDaysDiff] = useState(null);
  const [hoursDiff, setHoursDiff] = useState(null);
  const [minsDiff, setMinsDiff] = useState(null);
  const [intervalId, setIntervalId] = useState(undefined);
  const [isShow, setIsShow] = useState(false);

  const timeCal = () => {
    // @ts-ignore
    // console.log('countDownData:', countDownData);
    // @ts-ignore
    if (!countDownData) {
      return;
    }
    if (moment(countDownData.to).isBefore(moment())) {
      window.clearInterval(intervalId);
      document.location.reload();
      return;
    }
    // @ts-ignore
    const timeDiff = moment(countDownData.to).diff(moment());
    if (timeDiff >= 60 * 1000) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
    const diffDuration = moment.duration(timeDiff);
    const month = diffDuration.months();
    if (month > 0) {
      setDaysDiff(diffDuration.days() + +moment().add(month, 'months').daysInMonth());
    } else {
      setDaysDiff(diffDuration.days());
    }
    setHoursDiff(diffDuration.hours());
    setMinsDiff(diffDuration.minutes());
  };

  useEffect(() => {
    window.clearInterval(intervalId);
    timeCal();
    if (window) {
      setIntervalId(
        window.setInterval(() => {
          timeCal();
        }, 1000),
      );
    }
  }, [countDownData]);
  useEffect(() => {
    if (active === 0) {
      window.clearInterval(intervalId);
    }
  }, [active]);
  return (
    <div className="flex justify-center items-center pl-md countdown-wrapper">
      <div className="flex flex-row items-baseline">
        <h2 className="countdown-title font-regular text-white">{daysDiff}</h2>
        <p className="font-md text-white pl-1 mb-0 countdown-label">Day(s)</p>
      </div>
      <h2 className="countdown-title font-regular text-white px-2">:</h2>
      <div className="flex flex-row items-baseline">
        <h2 className="countdown-title font-regular text-white">{hoursDiff}</h2>
        <p className="font-md text-white pl-1 mb-0 countdown-label">Hour(s)</p>
      </div>
      <h2 className="countdown-title font-regular text-white px-2">:</h2>
      <div className="flex flex-row items-baseline">
        <h2 className="countdown-title font-regular text-white time-blinker">{minsDiff}</h2>
        <p className="font-md text-white pl-1 mb-0 countdown-label">Minute(s)</p>
      </div>
    </div>
  );
};
export default CountDown;
